import useShare from "react-use-web-share";

import useNotifications from "./useNotifications";

const useWebShare = (callback) => {
  const { addSuccessNotification, addErrorNotification } = useNotifications();

  const onSuccess = () => {
    callback();
    addSuccessNotification("The link was successfully shared");
  };

  const onError = (error) => {
    if (error.name !== "AbortError") {
      addErrorNotification(error.message);
    }
  };

  const { loading, isSupported, share } = useShare(onSuccess, onError);

  return {
    isWebShareSupported: isSupported,
    share,
    isWebShareProcessing: loading,
  };
};

export default useWebShare;
