/* global gtag */
/* global DetectUserAgent */
import { useEffect, useMemo, useState, useContext } from "react";
import Cookies from "js-cookie";

import { COOKIES } from "enums";
import { ExperimentContext } from "contexts/experiment-context";
import UserPresenter from "presenters/UserPresenter";
import MakePresenter from "presenters/MakePresenter";
import useUsers from "hooks/useUsers";
import useMakes from "hooks/useMakes";
import useSettings from "hooks/useSettings";
import useRouter from "hooks/useRouter";

const useGA4 = () => {
  const { currentUser } = useUsers();
  const {
    settings: { googleAnalytics4Key },
  } = useSettings();
  const { make } = useMakes();
  const {
    query: { debug_ga: debugGa = null },
  } = useRouter();
  const { experimentId } = useContext(ExperimentContext);
  const [debugGaOn, setDebugGaOn] = useState(null);

  const configParams = useMemo(() => {
    const config = {
      user_properties: {
        loggedIn: UserPresenter.isRegistered(currentUser) ? "yes" : "no",
        statusMembership: UserPresenter.isPaid(currentUser) ? "member" : "nonMember",
        userIsRecipient: MakePresenter.isRecipient(make) ? "yes" : "no",
        user_id: currentUser ? currentUser.id : null,
        experiment_id: experimentId || null,
      },
    };

    if (debugGaOn) config.debug_mode = true;

    return config;
  }, [debugGaOn, currentUser, make, experimentId]);

  useEffect(() => {
    if (debugGa === "true") {
      Cookies.set(COOKIES.DEBUG_GA_ON, true);
      setDebugGaOn(true);
    } else if (debugGa === "false") {
      Cookies.remove(COOKIES.DEBUG_GA_ON);
      setDebugGaOn(false);
    } else {
      const cookieValue = Cookies.get(COOKIES.DEBUG_GA_ON);
      setDebugGaOn(cookieValue === "true");
    }
  }, [debugGa]);

  useEffect(() => {
    if (debugGaOn === null || DetectUserAgent.isPrerender()) return;

    gtag("config", googleAnalytics4Key, configParams);
  }, [debugGaOn, configParams]);
};

export default useGA4;
