import { contentfulRoutes } from "routes";
import FetchHelpers from "utils/FetchHelpers";

export default {
  index(slugs) {
    const includeDraft = FetchHelpers.isContentfulPreview();
    const url = contentfulRoutes.templateGroups();
    const headers = { Accept: "application/vnd.api+json" };
    const params = { filter: { slugs } };
    if (includeDraft) params.filter["with-draft"] = true;
    return FetchHelpers.get(url, params, headers);
  },
  show(slug, includeTheme = false) {
    const includeDraft = FetchHelpers.isContentfulPreview();
    const url = contentfulRoutes.templateGroup(slug);
    const headers = { Accept: "application/vnd.api+json" };
    const params = {};
    if (includeDraft) params.filter = { "with-draft": true };
    if (includeTheme) params.includes = "theme";
    return FetchHelpers.get(url, params, headers);
  },
};
