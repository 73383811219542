import PropTypes from "prop-types";
import { isNil } from "ramda";

import Presenter from "utils/PropTypesPresenter";
import InvitePresenter from "presenters/InvitePresenter";
import MakePresenter from "presenters/MakePresenter";
import TemplateGroupPresenter from "presenters/TemplateGroupPresenter";
import TemplatePresenter from "presenters/TemplatePresenter";
import { ECARD_TYPES } from "enums";

export default new Presenter(
  {
    id: PropTypes.string,
    type: PropTypes.string,
    thumbnail: PropTypes.string,
  },
  {
    isMake(ecard) {
      return this.type(ecard) === ECARD_TYPES.MAKE;
    },
    templateId(ecard, template) {
      if (isNil(ecard)) return "";
      if (this.isMake(ecard)) return TemplatePresenter.id(template);
      return InvitePresenter.templateId(ecard);
    },
    template(ecard, templateGroup) {
      if (isNil(ecard)) return null;
      if (this.isMake(ecard)) return MakePresenter.template(ecard);
      return TemplateGroupPresenter.templateById(templateGroup, this.templateId(ecard));
    },
    templateGroupId(ecard) {
      return this.isMake(ecard) ? MakePresenter.templateGroupId(ecard) : InvitePresenter.templateGroupId(ecard);
    },
  },
);
