export default {
  helpPath: () => "https://jibjab.zendesk.com/hc/en-us",
  requestHelpPath: () => "https://help.jibjab.com/hc/en-us/requests/new",
  twitterPath: () => "https://twitter.com/jibjab",
  facebookPath: () => "https://www.facebook.com/jibjab",
  instagramPath: () => "https://www.instagram.com/jibjab",
  pinterestPath: () => "https://www.pinterest.com/jibjabmediainc/",
  youtubePath: () => "https://www.youtube.com/user/JibJab",
  recaptchaPrivacy: () => "https://policies.google.com/privacy",
  recaptchaTerms: () => "https://policies.google.com/terms",
  googleVisionPath: (key) => `https://vision.googleapis.com/v1/images:annotate?key=${key}`,
  fmvrPath: (makeId) => `${process.env.FMVR_API_GATEWAY}/${makeId}`,
  algoliaSearchProxy: () => `${process.env.ALGOLIA_SEARCH_PROXY}/search`,
  notSellMyDataLinkPath: (email = null) =>
    email
      ? `https://docs.google.com/forms/d/e/1FAIpQLScyn6JigE1kbAd6BoULVE4qsXoR5R5kEtuFHXR1fZiELWJVoA/viewform?usp=pp_url&entry.414375083=${email}`
      : "https://docs.google.com/forms/d/e/1FAIpQLScyn6JigE1kbAd6BoULVE4qsXoR5R5kEtuFHXR1fZiELWJVoA/viewform?usp=sf_link",
  jjAppStore: () => "https://apps.apple.com/us/app/jibjab/id875561136",
  jjGooglePlay: () =>
    "https://play.google.com/store/apps/details?id=com.jibjab.android.messages.fbmessenger&referrer=utm_source%3Dgetjibjab.com%26utm_medium%3Dlanding_page",
  jjOneLinkApps: () => "https://jibjab.onelink.me/WTYW/7vwwek3p",
  cjTracking: () => `${process.env.CJ_ENDPOINT}`,
  menu: (slug) =>
    process.env.ENVIRONMENT === "local"
      ? `http://localhost:3005/dev/v2/contentful/menu/${slug}`
      : `/v2/contentful/menu/${slug}`,
};
