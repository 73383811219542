import appRoutes from "routes/appRoutes";

export const NOT_AUTH_LINKS = [
  {
    name: "Sign Up",
    to: appRoutes.signUpPath(),
    activeLink: false,
    button: true,
  },
  {
    name: "Log In",
    to: appRoutes.loginPath(),
  },
];

export const AUTH_LINKS = [
  {
    id: "my-ecards",
    name: "My Ecards",
    to: appRoutes.myEcardsPath(),
  },
  {
    id: "my-invites",
    name: "My Invites",
    to: appRoutes.myInvitesPath(),
  },
  {
    id: "people",
    name: "People",
    to: appRoutes.peoplePath(),
  },
  {
    id: "account",
    name: "Account Settings",
    to: appRoutes.myAccountPath(),
  },
];
