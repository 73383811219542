import { apiRoutes, contentfulRoutes } from "routes";
import FetchHelpers from "utils/FetchHelpers";

export default {
  index(params) {
    const url = FetchHelpers.toCDN(apiRoutes.categoryPath(1, params));
    return FetchHelpers.get(url);
  },

  contentfulShow(slug) {
    const includeDraft = FetchHelpers.isContentfulPreview();
    const url = contentfulRoutes.category(slug);
    const headers = { Accept: "application/vnd.api+json" };
    const params = {};
    if (includeDraft) params.filter = { "with-draft": true };
    return FetchHelpers.get(url, params, headers);
  },
};
