import { FACE_PAGE_FLOWS, UPLOAD_SOURCES } from "enums";
import { appRoutes } from "routes";
import { allToBase64, rotateAndScale } from "utils/imageUtils";
import { trackEventHeadUploadSuccess } from "utils/gAnalyticsUtils";
import { trackPhotoUploadSuccess } from "tracking/google/analytics";

import useUsers from "./useUsers";
import useFaces from "./useFaces";
import useRouter from "./useRouter";

const useFacebookUploadFunctions = (closeModalFacebook) => {
  const { currentUser } = useUsers();
  const { pushWithSave } = useRouter();
  const { setPhoto, closeModal, clearPhoto, clearFoundFaces, resetAdded, setMode, clearFacesToAddPerson } = useFaces();
  const goToFace = () => pushWithSave(appRoutes.facePath());

  const cleanData = () => {
    clearPhoto();
    clearFoundFaces();
    resetAdded();
    clearFacesToAddPerson();
  };

  const prepareAndUpdatePhoto = (base64, newMode) => {
    setPhoto(base64);
    setMode(newMode);
    goToFace();
    closeModal();
    closeModalFacebook();
  };

  const setAndDetectPhoto = (base64) => {
    cleanData();
    prepareAndUpdatePhoto(base64, FACE_PAGE_FLOWS.LOADING);
  };

  const handleFacebookUploadSuccess = (photoUrl) => {
    if (photoUrl) {
      trackEventHeadUploadSuccess("Facebook", currentUser);
      trackPhotoUploadSuccess(UPLOAD_SOURCES.FACEBOOK);
      allToBase64(`${photoUrl}&redirect=false`)
        .then(rotateAndScale)
        .then(setAndDetectPhoto);
    }
  };

  return {
    handleFacebookUploadSuccess,
  };
};

export default useFacebookUploadFunctions;
