import PropTypes from "prop-types";
import { pathOr, pipe, filter, sort, sortBy, uniqBy, identity, isNil } from "ramda";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

import { appRoutes } from "routes";
import Presenter from "utils/PropTypesPresenter";
import TemplatePresenter from "presenters/TemplatePresenter";
import ThemePresenter from "presenters/ThemePresenter";
import { getPrimaryTag } from "utils/tagUtils";
import selectedCastNumber from "utils/selectedCastNumber";
import { TEMPLATE_GROUP_TYPES } from "enums";

export default new Presenter(
  {
    id: PropTypes.string,
    type: PropTypes.string,
    previewVideo: PropTypes.string,
    previewPoster: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    premium: PropTypes.bool,
    status: PropTypes.string,
    thumbnail: PropTypes.string,
    templateGroupType: PropTypes.string,
    defaultMakeMessage: PropTypes.string,
    tags: PropTypes.string,
    relatedTemplateGroups: PropTypes.arrayOf(PropTypes.shape),
    previewPoster169: PropTypes.string,
    ghostThumbnail: PropTypes.string,
    theme: ThemePresenter.shape(),
  },
  {
    uploadDate(tg) {
      return pathOr("", ["createdAt"], tg);
    },
    duration(tg) {
      dayjs.extend(duration);
      const videoDuration = pathOr(0, ["duration"], tg);
      return dayjs.duration(videoDuration, "s").toISOString();
    },
    castNumOptions(tg) {
      const castNumOptions = pathOr([], ["castNumOptions"], tg);
      const filtered = pipe(
        filter((n) => n !== 0),
        sortBy(identity),
        uniqBy(identity),
      );
      return filtered(castNumOptions);
    },
    firstCastNum(tg) {
      return this.castNumOptions(tg)[0];
    },
    castsCount(tg) {
      return tg && (selectedCastNumber.get() !== null ? selectedCastNumber.get() : this.firstCastNum(tg));
    },
    castingCardThumb(tg) {
      const template = this.template(tg);
      const { castingCardThumb } = template;
      return castingCardThumb;
    },
    template(tg) {
      const selectedCastNum = this.castsCount(tg);
      const filtered = filter((t) => t.castNum === selectedCastNum, tg.templates);

      if (filtered.length) return filtered[0];

      return this.templates(tg)[0];
    },
    templateById(tg, id) {
      if (!tg) return null;

      const filtered = filter((t) => t.id === id, tg.templates);

      if (filtered.length) return filtered[0];

      return this.templates(tg)[0];
    },
    templates(tg) {
      const { templates } = tg;
      const sorted = sort((a, b) => a.castNum - b.castNumm, templates);
      return sorted;
    },
    liveTemplates(tg) {
      return filter((t) => TemplatePresenter.isLive(t), this.templates(tg));
    },
    hasLiveTemplates(tg) {
      return this.liveTemplates(tg).length > 0;
    },
    isMultiTemplate(tg) {
      return this.castNumOptions(tg).length > 1;
    },
    isStraightUp(tg) {
      return tg && this.templateGroupType(tg) === TEMPLATE_GROUP_TYPES.straightUpEcard;
    },
    isStarringYouClip(tg) {
      return this.templateGroupType(tg) === TEMPLATE_GROUP_TYPES.starringYouClip;
    },
    isStarringYouVertical(tg) {
      return this.templateGroupType(tg) === TEMPLATE_GROUP_TYPES.starringYouVertical;
    },
    isStarringYou(tg) {
      return this.templateGroupType(tg) === TEMPLATE_GROUP_TYPES.starringYouEcard;
    },
    isStarringYouInvite(tg) {
      return this.templateGroupType(tg) === TEMPLATE_GROUP_TYPES.starringYouInvite;
    },
    isStarringYouInviteGif(tg) {
      return this.templateGroupType(tg) === TEMPLATE_GROUP_TYPES.starringYouInviteGif;
    },
    isInvite(tg) {
      return this.isStarringYouInvite(tg) || this.isStarringYouInviteGif(tg);
    },
    aspectRatio(tg) {
      switch (true) {
        case this.isStarringYouInviteGif(tg):
          return "4/3";
        case this.isStarringYouVertical(tg):
          return "9/16";
        default:
          return "16/9";
      }
    },
    isLive(tg) {
      return this.status(tg)?.toLowerCase() === "live";
    },
    isHidden(tg) {
      return this.status(tg)?.toLowerCase() === "hidden";
    },
    isDeleted(tg) {
      return this.status(tg)?.toLowerCase() === "deleted";
    },
    primaryTag(tg) {
      const tags = this.tags(tg);
      return getPrimaryTag(tags);
    },
    hasTag(tg, tag, secondTag = null) {
      const tags = this.tags(tg)
        .replace(/\s/g, "")
        .split(",");

      if (isNil(tags)) return false;

      return tags.find((value) => value.includes(tag) || value.includes(secondTag)) !== undefined;
    },
    viewTemplateUrl(tg) {
      const { protocol, host } = window.location;
      return tg && `${protocol}//${host}${appRoutes.templateGroupPath(tg.id)}`;
    },
    posterUrl(tg) {
      if (this.previewPoster169(tg)) {
        return this.previewPoster169(tg);
      }

      if (this.previewPoster(tg)) {
        return this.previewPoster(tg);
      }

      return this.thumbnail(tg);
    },
    mainTitle(tg) {
      const isClip = this.isStarringYouClip(tg);
      const isInvite = this.isInvite(tg);
      const name = this.name(tg);

      if (isClip) return `${name} Clip`;
      if (isInvite) return `${name} Invitation`;
      return `${name} Ecard`;
    },
    videoSize(tg) {
      switch (this.templateGroupType(tg)) {
        case TEMPLATE_GROUP_TYPES.starringYouInviteGif:
          return "400x300";
        case TEMPLATE_GROUP_TYPES.starringYouVertical:
          return "270x480";
        default:
          return "480x270";
      }
    },
  },
);
