import { useCookie } from "react-recipes";

import CJRepository from "repositories/CJRepository";

import { jjLogger } from "../utils/logUtils";

const useCJEvent = () => {
  const [cje] = useCookie("cje");

  const trackEvent = async (subscriptionId, currency, price, offerId) => {
    try {
      if (cje) {
        const params = {
          cjEvent: cje,
          subscriptionId,
          currency,
          offerId,
          price,
        };
        await CJRepository.track(params);
      } else {
        throw new Error(`The cje event cookie is not setup`);
      }
    } catch (error) {
      jjLogger.log(`useCJEvent: trackEvent(): ${error}`);
    }
  };

  return {
    trackEvent,
  };
};

export default useCJEvent;
