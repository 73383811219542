import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import localStorage, { ECARD, TEMPLATE_GROUP, IN_FLOW } from "utils/localStorage";

const initialState = {
  ecard: null,
  templateGroup: null,
  inFlow: false,
};

const lastEcardSlice = createSlice({
  name: "lastEcardSlice",
  initialState,
  reducers: {
    setEcard(state, { payload }) {
      const { ecard } = payload;
      state.ecard = ecard;
    },
    setTemplateGroup(state, { payload }) {
      const { templateGroup } = payload;
      state.templateGroup = templateGroup;
    },
    setInFlow(state, { payload }) {
      state.inFlow = payload;
    },
    clearData(state) {
      const { ecard, templateGroup, inFlow } = initialState;
      state.ecard = ecard;
      state.templateGroup = templateGroup;
      state.inFlow = inFlow;
    },
  },
});

export const { actions } = lastEcardSlice;
export default lastEcardSlice.reducer;

export const useLastEcardActions = () => {
  const dispatch = useDispatch();

  const setLastEcard = (ecard, templateGroup) => {
    localStorage.setItem(ECARD, ecard);
    localStorage.setItem(TEMPLATE_GROUP, templateGroup);
    dispatch(lastEcardSlice.actions.setEcard({ ecard }));
    dispatch(lastEcardSlice.actions.setTemplateGroup({ templateGroup }));
  };

  const setInFlow = (inFlow) => {
    localStorage.setItem(IN_FLOW, inFlow);
    dispatch(lastEcardSlice.actions.setInFlow(inFlow));
  };

  const clearLastEcard = () => {
    localStorage.removeItem(ECARD);
    localStorage.removeItem(TEMPLATE_GROUP);
    localStorage.removeItem(IN_FLOW);
    dispatch(lastEcardSlice.actions.clearData());
  };

  return {
    setLastEcard,
    setInFlow,
    clearLastEcard,
  };
};
