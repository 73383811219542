import { trackMakeEvent, IN_FLOW_SUBSCRIPTION_PURCHASE } from "utils/makeEventUtils";
import EcardPresenter from "presenters/EcardPresenter";
import { jjLogger } from "utils/logUtils";

import useAlgoliaEvents from "./useAlgoliaEvents";
import useAlgoliaTracker from "./useAlgoliaTracker";
import useFmvr from "./useFmvr";
import useMakes from "./useMakes";
import useLastEcard from "./useLastEcard";

const usePaymentEvents = () => {
  const { trackEventAfterSearchAndPurchase } = useAlgoliaEvents();
  const { hitSelected } = useAlgoliaTracker();
  const { updateMake } = useMakes();
  const { ecard, isMake, templateGroup } = useLastEcard();
  const { createFmvr } = useFmvr();

  const onSuccessfulInFlowPayment = async () => {
    try {
      if (ecard) {
        const templateGroupId = EcardPresenter.templateGroupId(ecard);
        if (templateGroupId === hitSelected?.objectID) trackEventAfterSearchAndPurchase(hitSelected);

        if (isMake) {
          await trackMakeEvent(ecard, IN_FLOW_SUBSCRIPTION_PURCHASE);
          await updateMake(ecard);
          createFmvr(ecard, templateGroup);
        }
      }
    } catch (error) {
      let errorLog = "usePaymentEvents.js: onSuccessfulInFlowPayment(): error: ";

      if (Array.isArray(error)) {
        error.forEach((err) => {
          errorLog += err;
        });
      } else {
        errorLog += error;
      }

      jjLogger.logError(errorLog);
    }
  };

  return { onSuccessfulInFlowPayment };
};

export default usePaymentEvents;
