import React, { lazy } from "react";

import { useFaces, useGlobalLoading, usePageUpdated, useFacebook } from "hooks";
import LoadingModal from "components/LoadingModal";

const FaceUploadModal = lazy(() => import("components/FaceUploadModal"));
const PageUpdatedModal = lazy(() => import("components/PageUpdatedModal"));
const FacebookModalNotice = lazy(() => import("components/FacebookModalNotice"));

const RenderModal = () => {
  const { modalOpened } = useFaces();
  const { isPending, labelFunc, progress } = useGlobalLoading();
  const { isUpdating } = usePageUpdated(true);
  const { modalOpen: facebookModalOpen, showNoticeModal } = useFacebook();

  return (
    <>
      {(modalOpened || facebookModalOpen) && <FaceUploadModal />}
      {isUpdating && <PageUpdatedModal />}
      {isPending && <LoadingModal labelFunc={labelFunc} progress={progress} />}
      {showNoticeModal && <FacebookModalNotice />}
    </>
  );
};

export default RenderModal;
