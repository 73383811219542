import CategorySlice from "store/CategorySlice";
import AppSlice from "store/AppSlice";
import AuthSlice from "store/AuthSlice";
import FeaturedEcardsSlice from "store/FeaturedEcardsSlice";
import PillsSlice from "store/PillsSlice";
import TemplateGroupSlice from "store/TemplateGroupSlice";
import FacesSlice from "store/FacesSlice";
import MakesSlice from "store/MakesSlice";
import TransactionRecordSlice from "store/TransactionRecordSlice";
import SearchSlice from "store/SearchSlice";
import LoadingSlice from "store/LoadingSlice";
import SharesSlice from "store/SharesSlice";
import MakeMessagesSlice from "store/MakeMessagesSlice";
import ResetPasswordSlice from "store/ResetPasswordSlice";
import IdentitiesSlice from "store/IdentitiesSlice";
import OfferGroupsSlice from "store/OfferGroupsSlice";
import OffersSlice from "store/OffersSlice";
import SubscriptionsSlice from "store/SubscriptionsSlice";
import PaypalSlice from "store/PaypalSlice";
import PeopleSlice from "store/PeopleSlice";
import JoinModalSlice from "store/JoinModalSlice";
import PersonFacesSlice from "store/PersonFacesSlice";
import CalendarSlice from "store/CalendarSlice";
import CookieBannerSlice from "store/CookieBannerSlice";
import PageUpdatedSlice from "store/PageUpdatedSlice";
import AlgoliaTrackerSlice from "store/AlgoliaTrackerSlice";
import AlgoliaSlice from "store/AlgoliaSlice";
import FacebookSlice from "store/FacebookSlice";
import MenuSlice from "store/MenuSlice";
import InvitesSlice from "store/InvitesSlice";
import PlayerControlsSlice from "store/PlayerControlsSlice";
import LastEcardSlice from "store/LastEcardSlice";
import PageSlice from "store/PageSlice";

import HomePageSlice from "./HomePageSlice";

export default {
  AppSlice,
  AuthSlice,
  CategorySlice,
  FacesSlice,
  FeaturedEcardsSlice,
  IdentitiesSlice,
  JoinModalSlice,
  LoadingSlice,
  MakeMessagesSlice,
  MakesSlice,
  OfferGroupsSlice,
  OffersSlice,
  PaypalSlice,
  PeopleSlice,
  PersonFacesSlice,
  PillsSlice,
  ResetPasswordSlice,
  SearchSlice,
  SharesSlice,
  SubscriptionsSlice,
  TemplateGroupSlice,
  TransactionRecordSlice,
  CalendarSlice,
  CookieBannerSlice,
  PageUpdatedSlice,
  AlgoliaTrackerSlice,
  AlgoliaSlice,
  FacebookSlice,
  HomePageSlice,
  MenuSlice,
  InvitesSlice,
  PlayerControlsSlice,
  LastEcardSlice,
  PageSlice,
};
